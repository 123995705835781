import { GetterTree } from 'vuex';
import { RunsState } from '@/store/modules/runs/runsState';
import { RootState } from '@/store/rootState';
import { Notification } from '@/core/interfaces/notification';
import { Run } from '@/core/interfaces/run';

const getters: GetterTree<RunsState, RootState> = {
    notifications: (state: RunsState, getters, rootState, rootGetters): Notification[] =>
        state.notifications.filter(notification => {
            if (rootState.visibleVehicleTypeIds.length === 0) {
                return true;
            }

            const vehicle = rootGetters.vehicleById(notification.vehicle_id);

            if (!vehicle) {
                return true;
            }

            return rootState.visibleVehicleTypeIds.includes(vehicle.vehicle_type_id);
        }),
    runs: (state: RunsState): Run[] => state.runs,
    runById: (state: RunsState) => (runId: number): Run =>
        state.runs.find(run => run.id === runId) as Run,
    openNotifications: (state: RunsState, getters): Notification[] =>
        getters.notifications.filter((notification: Notification) => !notification.ended_at),
    assignedNotifications: (state: RunsState, getters): Notification[] =>
        getters.notifications.filter((notification: Notification) => !!notification.assigned_to_user_id),
    notificationsAssignedToMe: (state: RunsState, getters, rootState, rootGetters): Notification[] => getters
        .notifications.filter((notification: Notification) => notification.assigned_to_user_id === rootGetters.currentUserId),
    notificationsAssignedToOthers: (state: RunsState, getters, rootState, rootGetters): Notification[] =>
        getters.notifications.filter((notification: Notification) => notification.assigned_to_user_id && notification.assigned_to_user_id !== rootGetters.currentUserId),
    notificationsByRun: (state: RunsState, getters) => (runId: number): Notification[] => getters
        .notifications.filter((notification: Notification) => notification.notification_run_id === runId),
    notificationIsAssignedToMe: (state: RunsState, getters, rootState, rootGetters) => (notification: Notification): boolean =>
        notification.assigned_to_user_id === rootGetters.currentUserId,
    notificationsByVehicle: (state: RunsState, getters) => (vehicleId: number): Notification[] => getters
        .openNotifications
        .filter((notification: Notification) => notification.vehicle_id === vehicleId),
    tableNotifications: (state: RunsState) => state.tableNotifications,
    tableRuns: (state: RunsState) => state.tableRuns,
    tableLoading: (state: RunsState) => state.tableLoading,
    tableTotalNumberOfRecords: (state: RunsState) => state.tableTotalNumberOfRecords,
};

export default getters;
