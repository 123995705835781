import { VehicleDashboardState } from '@/store/modules/vehicleDashboard/vehicleDashboardState';
import { DashboardDefinition } from '@/core/interfaces/dashboardDefinition';
import { GeoJSON } from 'geojson';
import Vue from 'vue';

const mutations = {
    setMachine: (state: VehicleDashboardState, payload: string) => {
        state.machine = payload;
    },
    setDefinition: (state: VehicleDashboardState, payload: DashboardDefinition) => {
        state.definition = payload;
    },
    setStatus: (state: VehicleDashboardState, payload: string) => {
        state.status = payload;
    },
    setStatusTopic: (state: VehicleDashboardState, payload: string) => {
        state.statusTopic = payload;
    },
    setCamera: (state: VehicleDashboardState, payload: string) => {
        state.camera = payload;
    },
    setMaintenanceMode: (state: VehicleDashboardState, payload: boolean) => {
        state.maintenanceMode = payload;
    },
    setPaused: (state: VehicleDashboardState, payload: boolean) => {
        state.paused = payload;
    },
    setFollowingFootprint: (state: VehicleDashboardState, payload: boolean) => {
        state.followingFootprint = payload;
    },
    setCameraTopic: (state: VehicleDashboardState, payload: string) => {
        state.cameraTopic = payload;
    },
    setRealtimeMapLayer: (state: VehicleDashboardState, payload: { topic: string; geojson: GeoJSON }) => {
        Vue.set(state.realtimeMapLayers, payload.topic, payload.geojson);
    },
    setLaserscan: (state: VehicleDashboardState, payload: GeoJSON) => {
        state.laserscan = payload;
    },
    setFootprint: (state: VehicleDashboardState, payload: GeoJSON) => {
        state.footprint = payload;
    },
    setRobotPosition: (state: VehicleDashboardState, payload: { x: number; y: number }) => {
        state.robotPosition = payload;
    },
    clearRealtimeMapLayers: (state: VehicleDashboardState) => {
        state.realtimeMapLayers = {};
    },
};

export default mutations;
