import { RootState } from '@/store/rootState';
import { Farm } from '@/core/interfaces/farm';
import { Vehicle } from '@/core/interfaces/vehicle';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { User } from '@/core/interfaces/user';
import { Role } from '@/core/interfaces/role';
import { NotificationType } from '@/core/interfaces/notificationType';
import { VehiclePhase } from '@/core/interfaces/vehiclePhase';
import { Phone } from '@/core/interfaces/phone';

const colors = [
    '#73bd07',
    '#1F78B4',
    '#FD3535',
    '#766E68',
    '#FFC700',
    '#C56A28',
    '#4D33DC',
];

let colorIndex = 0;
const roleColors: Record<number, string> = {};

export const farmsSet = (state: RootState): Farm[] =>
    state.farmsList.map((id: number) => state.farms[id]).sort((a, b) =>
        a.name.localeCompare(b.name));

export const farmsByVehicleType = (state: RootState, getters: any) => (vehicleTypeId: number): Farm[] =>
    state.farmVehicleTypes
        .filter((farmVehicleType) => farmVehicleType.vehicle_type_id === vehicleTypeId)
        .map((farmVehicleType) => getters.farmById(farmVehicleType.farm_id));

export const vehiclesSet = (state: RootState): Vehicle[] =>
    state.vehiclesList.map((id: number) => state.vehicles[id]).sort((a, b) =>
        a.name.localeCompare(b.name));

export const vehicleTypesSet = (state: RootState): VehicleType[] =>
    state.vehicleTypesList.map((id: number) => state.vehicleTypes[id])
        .sort((a, b) =>
            a.name.localeCompare(b.name));

export const usersSet = (state: RootState): User[] =>
    state.usersList.map((id: number) => state.users[id])
        .sort((a, b) =>
            a.username.localeCompare(b.username));

export const rolesSet = (state: RootState): Role[] =>
    state.rolesList.map((id: number) => state.roles[id])
        .sort((a, b) =>
            a.code_name.localeCompare(b.code_name));

export const notifyableRolesSet = (state: RootState, getters: any): Role[] =>
    getters.rolesSet.filter((role: Role) => role.notifyable);

export const assignableRolesSet = (state: RootState, getters: any): Role[] =>
    getters.rolesSet.filter((role: Role) => role.assignable);

export const notificationTypesSet = (state: RootState): NotificationType[] =>
    state.notificationTypesList.map((id: number) => state.notificationTypes[id])
        .sort((a, b) => a.code - b.code);

export const vehiclePhasesSet = (state: RootState): VehiclePhase[] =>
    state.vehiclePhasesList.map((id: number) => state.vehiclePhases[id]);

export const currentUser = (state: RootState): User | null => state.currentUser;

export const currentUserId = (state: RootState): number | null => state.currentUser?.id || null;

export const isAdmin = (state: RootState, getters: any): boolean => getters.currentUser?.admin;

export const userById = (state: RootState) => (id: number): User => state.users[id];

export const farmById = (state: RootState) => (id: number): Farm => state.farms[id];

export const vehiclePhaseById = (state: RootState) => (id: number): VehiclePhase => state.vehiclePhases[id];

export const vehicleById = (state: RootState) => (id: number): Vehicle => state.vehicles[id];

export const vehiclesByFarm = (state: RootState, getters: any) => (farmId: number): Vehicle[] => getters.vehiclesSet
    .filter((vehicle: Vehicle) => vehicle.farm_id === farmId);

export const vehicleTypeById = (state: RootState) => (id: number): VehicleType => state.vehicleTypes[id];

export const vehicleTypeByName = (state: RootState) => (name: string): VehicleType => Object.values(state.vehicleTypes).find(type => type.name === name) as VehicleType;

export const notificationTypeById = (state: RootState) => (id: number): NotificationType => state.notificationTypes[id];

export const notificationTypeByCode = (state: RootState) => (code: number, vehicleTypeId: number): NotificationType => Object.values(state.notificationTypes)
    .find(type => type.code === code && type.vehicle_type_id === vehicleTypeId) as NotificationType;

export const notificationTypesByVehicleTypeId = (state: RootState) => (vehicleTypeId: number): NotificationType[] => Object.values(state.notificationTypes)
    .filter(type => type.vehicle_type_id === vehicleTypeId)
    .sort((a, b) => a.code - b.code);

export const roleById = (state: RootState) => (id: number): Role => state.roles[id];

export const assignableRoles = (state: RootState, getters: any): Role[] => getters.rolesSet
    .filter((role: Role) => role.assignable);

export const roleByName = (state: RootState, getters: any) => (name: string): Role => getters.rolesSet
    .find((role: Role) => role.code_name === name);

export const usersByRoleId = (state: RootState) => (roleId: number): User[] => state.userRoles
    .filter(userRole => userRole.role_id === roleId)
    .filter(userRole => state.usersList.includes(userRole.user_id))
    .map(userRole => state.users[userRole.user_id])
    .sort((a, b) => a.username.localeCompare(b.username));

export const roleColorById = (state: RootState) => (roleId: number): string => {
    if (Object.keys(roleColors).includes(String(roleId))) {
        return roleColors[roleId];
    }

    roleColors[roleId] = colors[colorIndex];
    colorIndex++;

    return roleColors[roleId];
};

export const localeKeys = (state: RootState): string[] => Object.keys(state.locales);

export const phonesSet = (state: RootState, getters: any): Phone[] =>
    state.phones
        .sort((a, b) =>
            getters.userById(a.user_id)?.username.localeCompare(getters.userById(b.user_id)?.username));

export const phoneById = (state: RootState) => (phoneId: number): Phone =>
    state.phones.find(phone => phone.id === phoneId) as Phone;

export const phoneByUdid = (state: RootState) => (udid: string): Phone =>
    state.phones.find(phone => phone.phone_udid === udid) as Phone;

export const visibleVehicleTypeIds = (state: RootState): number[] => state.visibleVehicleTypeIds;

export const visibleVehicleTypes = (state: RootState, getters: any): VehicleType[] =>
    state.visibleVehicleTypeIds.map(id => getters.vehicleTypeById(id));
