import { VehicleDashboardState } from '@/store/modules/vehicleDashboard/vehicleDashboardState';

const state: VehicleDashboardState = {
    machine: null,
    definition: null,
    statusTopic: null,
    status: null,
    cameraTopic: null,
    camera: null,
    maintenanceMode: false,
    paused: false,
    followingFootprint: false,
    realtimeMapLayers: {},
    laserscan: null,
    footprint: null,
    robotPosition: null,
};

export default state;
