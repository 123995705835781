
















import { Component, Vue } from 'vue-property-decorator';
import LocaleSelect from '@/components/LocaleSelect.vue';
import LoginLayout from '@/components/views/layouts/LoginLayout.vue';
import store from '@/store';
import { loadLocaleForVehiclesTypes } from '@/i18n';
import { LelyUserDefinition } from '@tec/frontend-vue-shared';

@Component({
    components: { LoginLayout, LocaleSelect },
})
export default class Login extends Vue {
    async authenticated(user: LelyUserDefinition, redirectTo?: string): Promise<void> {
        this.$store.commit('setCurrentUser', user);
        this.$store.dispatch('runs/syncRuns').then();
        this.$store.dispatch('tickets/syncOpenTickets').then();
        await this.$store.dispatch('syncMasterData');
        await this.$store.dispatch('initSyncInterval');
        await loadLocaleForVehiclesTypes(store.getters.localeKeys, store.getters.vehicleTypesSet);

        if (redirectTo) {
            await this.$router.push({ path: redirectTo });
        } else {
            await this.$router.push({ name: 'open' });
        }
    }
}

