



















import '@fullcalendar/core/vdom';
import { CalendarOptions, diffDays } from '@fullcalendar/core';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import interactionPlugin from '@fullcalendar/interaction';
import timegridPlugin from '@fullcalendar/timegrid';
import FullCalendar, { CalendarApi, DateSelectArg, DatesSetArg, EventClickArg } from '@fullcalendar/vue';
import { EventInput } from '@fullcalendar/common';
import { addDays, addHours, formatISO, isEqual, parseISO } from 'date-fns';
import { Timetable } from '@/core/interfaces/timetable';
import { User } from '@/core/interfaces/user';
import { Role } from '@/core/interfaces/role';
import SettingsTimeblockForm from '@/components/settings/timetable/SettingsTimeblockForm.vue';

const settings = namespace('settings');

const blocks = [0, 8, 17];

@Component({
    components: {
        SettingsTimeblockForm,
        FullCalendar,
    },
})
export default class SettingsTimetable extends Vue {
    @settings.Getter('timetable')
    timetable!: (interval: { start: Date; end: Date }, roleIds?: number[]) => Timetable[];

    @Getter('roleByName')
    roleByName!: (name: string) => Role;

    @Getter('userById')
    userById!: (id: number) => User;

    @Getter('assignableRoles')
    roles!: Role[];

    @Getter('roleById')
    roleById!: (id: number) => Role;

    @Getter('roleColorById')
    roleColorById!: (id: number) => string;

    @settings.Getter('timetableById')
    timetableById!: (id: number) => Timetable;

    @Getter('currentUserId')
    currentUserId!: number;

    @settings.Action('syncTimetable')
    syncTimetable!: (payload: { dateFrom: Date; dateUntil: Date }) => Promise<void>;

    @settings.Action('saveTimetable')
    saveTimetable!: (payload: Partial<Timetable>) => Promise<void>;

    formTimetable: Partial<Timetable> | null = null;

    rangeStart: Date | null = null;
    rangeEnd: Date | null = null;

    get fcApi(): CalendarApi {
        return (this.$refs.fc as any).getApi();
    }

    get timeblocks(): EventInput[] {
        if (!this.rangeStart || !this.rangeEnd) {
            return [];
        }
        const timetable = this.timetable({ start: this.rangeStart, end: this.rangeEnd }, [this.operatorRole.id]);
        const days = diffDays(this.rangeStart, this.rangeEnd);
        const timeblocks = [];

        for (let dayIdx = 0; dayIdx < days; dayIdx++) {
            const date = addDays(this.rangeStart, dayIdx);

            for (let blockIdx = 0; blockIdx < blocks.length; blockIdx++) {
                const start = addHours(date, blocks[blockIdx]);

                let end: Date;
                if (blockIdx === blocks.length - 1) {
                    end = addHours(date, 24);
                } else {
                    end = addHours(date, blocks[blockIdx + 1]);
                }

                const existingTimetable = timetable.find(item => isEqual(parseISO(item.start_date), start) && isEqual(parseISO(item.end_date), end));
                const assignedToMe = existingTimetable ? existingTimetable.user_id === this.currentUserId : false;

                timeblocks.push({
                    start,
                    end,
                    className: existingTimetable ? (assignedToMe ? 'timeblock-me' : 'timeblock') : 'timeblock-available',
                    editable: false,
                    hour12: false,
                    title: existingTimetable ? this.userById(existingTimetable.user_id).username : '',
                    timetable: existingTimetable,
                } as EventInput);
            }
        }

        return timeblocks;
    }

    @Watch('timeblocks', { deep: true })
    updateEvents(): void {
        this.fcApi.refetchEvents();
    }

    public async refetchEvents(): Promise<void> {
        const dateFrom = this.fcApi.view.currentStart;
        const dateUntil = this.fcApi.view.currentEnd;

        await this.syncTimetable({ dateFrom, dateUntil });
        this.updateEvents();
    }

    get calendarOptions(): CalendarOptions {
        return {
            eventSources: [
                {
                    id: 'timeblocks',
                    events: (info, successCallback) => successCallback(this.timeblocks),
                },
            ],
            eventTimeFormat: {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            },
            slotLabelFormat: {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            },
            slotDuration: {
                hours: 1,
            },
            datesSet: data => this.datesSet(data),
            firstDay: 1,
            plugins: [interactionPlugin, timegridPlugin],
            initialView: 'timeGridWeek',
            allDaySlot: false,
            height: 'auto',
            selectable: false,
            eventClick: data => this.eventClicked(data),
            editable: false,
        };
    }

    private async datesSet(data: DatesSetArg): Promise<void> {
        this.rangeStart = data.start;
        this.rangeEnd = data.end;

        await this.syncTimetable({ dateFrom: data.start, dateUntil: data.end });
    }

    private async eventClicked(data: EventClickArg): Promise<void> {
        this.formTimetable = {
            start_date: formatISO(data.event.start as Date),
            end_date: formatISO(data.event.end as Date),
            user_id: data.event.extendedProps.timetable?.user_id,
            id: data.event.extendedProps.timetable?.id,
        };

        this.$bvModal.show('event-form');
    }

    get operatorRole(): Role {
        return this.roleByName('operator');
    }
}
