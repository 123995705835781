import { GetterTree } from 'vuex';
import { VehicleDashboardState } from '@/store/modules/vehicleDashboard/vehicleDashboardState';
import { RootState } from '@/store/rootState';
import { GeoJSON } from 'geojson';

const getters: GetterTree<VehicleDashboardState, RootState> = {
    realtimeMapLayerData: (state: VehicleDashboardState): Record<string, GeoJSON> => {
        if (!state.definition?.realtime_map_layers) {
            return {};
        }

        return state.definition.realtime_map_layers.reduce((acc: Record<string, GeoJSON>, realtimeMapLayer) => {
            const geojson = state.realtimeMapLayers[realtimeMapLayer.topic];

            if (geojson) {
                acc[realtimeMapLayer.topic] = geojson;
            }

            return acc;
        }, {});
    },

    realtimeFootprintTopic: (state: VehicleDashboardState) => {
        if (!state.definition?.realtime_map_layers) {
            return null;
        }

        return state.definition.realtime_map_layers.find(layer => layer.topic === '/ui/footprint')?.topic || null;
    },
};

export default getters;
